import { useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { getTimeZones } from '@vvo/tzdb';
import PropTypes from 'prop-types';

function useTimeZoneList() {
  const runtimeSupportedTimezone = new Set(Intl.supportedValuesOf('timeZone'));
  return getTimeZones({ includeUtc: true }).filter((tzObject) => runtimeSupportedTimezone.has(tzObject.name));
}

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  autoCompleteProps: PropTypes.shape({}),
};

function TimeZoneSelector({ onChange, value, autoCompleteProps }) {
  const timeZoneList = useTimeZoneList();

  const initialValue = timeZoneList.find((item) => item.name === value || item.group.includes(value));

  const [localValue, setLocaleValue] = useState(initialValue);

  const onLocalChange = (event, newValue) => {
    setLocaleValue(newValue);
    onChange(event, newValue.name);
  };

  return (
    <Autocomplete
      {...autoCompleteProps}
      options={timeZoneList}
      fullWidth
      disableClearable
      renderInput={(params) => <TextField {...params} label="Select prefered Timezone" />}
      getOptionLabel={(option) => `${option.abbreviation} ${option.alternativeName} - ${option.mainCities.join(', ')}`}
      isOptionEqualToValue={(option, optionValue) => option.name === optionValue.name}
      value={localValue}
      onChange={onLocalChange}
    />
  );
}

TimeZoneSelector.propTypes = propTypes;

export default TimeZoneSelector;
