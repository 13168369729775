import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { values } from 'mobx';
import { observer } from 'mobx-react';

import MaintenanceWindowsList from '../components/MaintenanceWindowsList/MaintenanceWindowsList';
import MonitorList from '../components/MonitorsList/MonitorList';
import NotesSection from '../components/NotesSection/NotesSection';
import PageHeader from '../components/PageHeader/PageHeader';
import SettingsDialog from '../components/SettingsDialog/SettingsDialog';
import { useStore } from '../store/useStore';

const LOCAL_STORAGE_KEY_USER_SETTINGS = 'user_settings';

const HomePage = observer(() => {
  const store = useStore();
  const [monitorList, setMonitorList] = useState([]);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [settingsDialogOpened, setSettingsDialogOpen] = useState(false);

  const [settings, setSettings] = useState({
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    const storedSettingsString = localStorage.getItem(LOCAL_STORAGE_KEY_USER_SETTINGS);

    if (storedSettingsString) {
      try {
        const storedSettings = JSON.parse(storedSettingsString);
        setSettings({
          ...settings,
          ...storedSettings,
        });
      } catch (e) {
        // JSON deserialization error, just remove the corrupted settings string from localeStorage and use default one
        localStorage.removeItem(LOCAL_STORAGE_KEY_USER_SETTINGS);
      }
    }

    store.noteStore.fetchNotes().then(() => {
      setNoteList(store.noteStore.notes);
    });

    store.maintenanceStore.fetchMaintenances().then(() => {
      setMaintenanceList(store.maintenanceStore.upcomingMaintenances);
    });

    store.metricStore.fetchMonitors().then(() => {
      values(store.metricStore.monitors).forEach((monitor) => {
        monitor.fetchMetrics().then(() => {
          setMonitorList(values(store.metricStore.monitors));
        });
      });
    });
  }, []);

  const onSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const onSettingsDialogClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_SETTINGS, JSON.stringify(settings));
    setSettingsDialogOpen(false);
  };

  return (
    <>
      <PageHeader onSettingsButtonClick={() => setSettingsDialogOpen(true)} />

      {/* Settings dialog */}

      <SettingsDialog
        settings={settings}
        onChange={onSettingsChange}
        onClose={onSettingsDialogClose}
        open={settingsDialogOpened}
      />

      {/* End Settings dialog */}

      <Container maxWidth="md" sx={{ mb: 5, mt: 5 }}>
        {/* Notes Section */}

        <section style={{ paddingTop: (store.noteStore.notes.length > 0 ? '60px' : '0') }}>
          { (store.noteStore.loadingNotes)
            ? <CircularProgress />
            : <NotesSection notes={noteList} />}
        </section>

        {/* Notes End Section */}

        {/* Maintenance Cards Area */}

        <section style={{ marginBottom: '20px', paddingTop: '30px' }}>
          <Typography variant="h2">Upcoming Maintenance</Typography>

          { (store.maintenanceStore.loadingMaintenances)
            ? <CircularProgress />
            : <MaintenanceWindowsList maintenanceWindows={maintenanceList} timeZone={settings.timeZone} />}
        </section>

        {/* End Maintenance Cards Area */}

        {/* Availability Cards Area */}

        <section style={{ display: 'flex', flexFlow: 'column' }}>
          <Typography variant="h2">Availability</Typography>

          { (monitorList.length === 0)
            ? <CircularProgress />
            : <MonitorList monitors={monitorList} /> }
        </section>

        {/* End Availability Cards Area */}
      </Container>
    </>
  );
});

export default HomePage;
