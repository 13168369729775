import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

const propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

function NotesSection({ notes }) {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {notes.map((note) => (
        <Alert severity={note.type} key={`note_${note.id}`}>
          <AlertTitle>{note.title}</AlertTitle>

          {note.description}
        </Alert>
      ))}
    </Stack>
  );
}

NotesSection.propTypes = propTypes;
export default NotesSection;
