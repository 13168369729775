import { flow, types } from 'mobx-state-tree';

import { getNotes } from '../api/notes';
import NoteModel from './models/NoteModel';

const NoteStore = types.model('NoteStore', {
  notes: types.array(NoteModel),
  loadingNotes: types.optional(types.boolean, false),
})
  .actions((self) => ({
    addNote(note) {
      self.notes.push({
        id: note.id,
        type: note.type,
        title: note.title,
        description: note.description,
      });
    },
    fetchNotes: flow(function* fetchNotes() { // <- note the star, this a generator function!
      self.loadingNotes = true;
      // ... yield can be used in async/await style
      yield getNotes()
        .then((response) => response.json())
        .then((data) => {
          /* eslint-disable */
          data.notes.forEach((note) => {
            self.addNote(note);
          });
        });
      self.loadingNotes = false;
    }),
  }));

export default NoteStore;
