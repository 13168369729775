import {
  DateRangeOutlined,
  // DomainOutlined,
  WatchLaterOutlined,
} from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const propTypes = {
  timeZone: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    nextWindowEndsAt: PropTypes.instanceOf(Date),
    nextWindowStartsAt: PropTypes.instanceOf(Date),
  }),
};

const CardMaintenance = ({ item, timeZone }) => {
  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
    timeStyle: 'long',
    timeZone,
  });

  const durationMinutes = Math.round(
    (item.nextWindowEndsAt.getTime() - item.nextWindowStartsAt.getTime()) / 60_000,
  );

  // Fix locale to en-US since all text is displayed in this locale anyway
  const minutesFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    unitDisplay: 'short',
  });

  return (
    <Card tabIndex="0" style={{ transition: 'all 0.3s ease 0s' }}>
      <CardContent tabIndex="0" style={{ paddingBottom: '12px', paddingRight: '12px', paddingLeft: '12px' }}>
        <Typography
          gutterBottom
          variant="h6"
          style={{ paddingRight: '12px', paddingLeft: '12px', backgroundColor: 'rgba(25, 118, 210, 0.08)' }}
        >
          {item.name}
        </Typography>

        {/* Description */}

        <Box style={{ paddingBottom: '12px', paddingRight: '12px', paddingLeft: '12px' }}>
          <Typography mb={1} variant="body2">{`${item.description}`}</Typography>

          <Divider />

          <Stack direction="column" gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <WatchLaterOutlined />

              <Stack direction="column">
                <Typography mt={1} variant="body2" style={{ fontWeight: '500' }}>
                  Duration
                </Typography>

                <Typography variant="body2">
                  {minutesFormatter.format(durationMinutes)}
                </Typography>
              </Stack>
            </Stack>

            {/* Maintenance time */}

            <Stack direction="row" alignItems="center" gap={1}>
              <DateRangeOutlined />

              <Stack direction="column">
                <Typography variant="body2" style={{ fontWeight: '500' }}>
                  Maintenance Time
                </Typography>

                <Typography variant="body2">
                  {timeFormatter.format(item.nextWindowStartsAt)}

                  {' '}
                  -

                  {' '}

                  {timeFormatter.format(item.nextWindowEndsAt)}
                </Typography>
              </Stack>
            </Stack>

            {/* Affected Components */}

            {/* <Stack direction="row" alignItems="center" gap={1}>
            <DomainOutlined />

            <Stack direction="column">
              <Typography variant="body2" style={{ fontWeight: '500' }}>
                Affected Components
              </Typography>

              <Typography variant="body2">{`${item.affectedComponents}`}</Typography>
            </Stack>
          </Stack> */}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
CardMaintenance.propTypes = propTypes;
export default CardMaintenance;
