import { ConfigClient, DYNATRACE_DASHBOARD_ID } from '../config/ConfigClient';
import ApiClient from './ApiClient';

export function getDashboardMonitors() {
  const route = `${ApiClient.apiUrl}/config/v1/dashboards/${ConfigClient.get(DYNATRACE_DASHBOARD_ID)}`;
  return ApiClient.client.get(route);
}

export function getAvailableMonitors() {
  const route = `${ApiClient.apiUrl}/v1/synthetic/monitors?enabled=true&type=HTTP`;
  return ApiClient.client.get(route);
}

export function getMonitorMetrics(monitorId) {
  const searchParams = new URLSearchParams({
    metricSelector: `builtin:synthetic.http.availability.location.total:avg:filter(eq("dt.entity.http_check","${monitorId}"))`,
    resolution: '30d',
  });

  const route = `${ApiClient.apiUrl}/v2/metrics/query?${searchParams.toString()}`;
  return ApiClient.client.get(route);
}

export function getEvents() {
  const route = `${ApiClient.apiUrl}/v2/events?eventSelector=eventType("HTTP_CHECK_GLOBAL_OUTAGE")`;
  return ApiClient.client.get(route);
}

export function getUpcomingMaintenance() {
  const route = `${ApiClient.apiUrl}/config/v1/maintenanceWindows`;
  return ApiClient.client.get(route);
}

export function getMaintenanceDetails(maintenanceId) {
  const route = `${ApiClient.apiUrl}/config/v1/maintenanceWindows/${maintenanceId}`;
  return ApiClient.client.get(route);
}
