import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'mobx-react';

import HomePage from './pages/HomePage';
import { createStore } from './store/createStore';
import useTheme from './theme/useTheme';
// import { useMemo, useState } from 'react';

const store = createStore();

function App() {
  /* const defaultPrimaryColor = '#FF0000';
  const defaultSecondaryColor = '#00FF00';
  const [primaryColor, setPrimaryColor] = useState(defaultPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState(defaultSecondaryColor);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: { main: primaryColor },
          secondary: {main: secondaryColor}
        },
      }),
    [primaryColor, secondaryColor],
  ); */

  const theme = useTheme();

  return (
    <Provider store={store}>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <HomePage />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
