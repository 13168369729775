import { types } from 'mobx-state-tree';

import MetricData from './MetricData';

const MetricModel = types
  .model({
    totalCount: types.integer,
    nextPageKey: types.maybeNull(types.string),
    resolution: types.string,
    data: types.array(MetricData),
    avgValue: types.maybeNull(types.number),
  })
  .actions((self) => ({
    addData(data) {
      // create a metric data and add to the array
      const metricData = MetricData.create({
        syntheticLocation: data.syntheticLocation,
        timestamp: data.timestamp,
        value: data.value,
      });
      self.data.push(metricData);

      // calc the new average value
      let totalValue = 0;
      self.data.forEach((dataItem) => { totalValue += dataItem.value; });
      self.avgValue = totalValue / self.data.length;
    },
  }));

export default MetricModel;
