import { useState, useEffect } from 'react';

import { HelpOutlined } from '@mui/icons-material';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';

import TimeZoneSelector from '../TimeZoneSelector/TimeZoneSelector';

const propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  settings: PropTypes.shape({
    timeZone: PropTypes.string,
  }),
};

export default function SettingsDialog({
  onChange, onClose, open, settings,
}) {
  const [timeZone, setTimeZone] = useState(settings.timeZone);

  const onTimeZoneChange = (event, newValue) => {
    setTimeZone(newValue);
    onChange({
      ...settings,
      timeZone: newValue,
    });
  };

  useEffect(() => {
    setTimeZone(settings.timeZone);
  }, [settings]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Settings</DialogTitle>

      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
          sx={{ marginTop: '1rem' }}
        >
          <TimeZoneSelector
            onChange={onTimeZoneChange}
            value={timeZone}
          />

          <Tooltip title="Timezone that will be used to display date and time">
            <HelpOutlined htmlColor="rgba(0, 0, 0, 0.6)" />
          </Tooltip>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

SettingsDialog.propTypes = propTypes;
