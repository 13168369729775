import { types } from 'mobx-state-tree';

import MaintenanceStore from './maintenanceStore';
import MetricStore from './metricStore';
import NoteStore from './noteStore';

const RootStore = types.model('RootStore', {
  metricStore: MetricStore,
  maintenanceStore: MaintenanceStore,
  noteStore: NoteStore,
});

export function createStore() {
  const metricStore = MetricStore.create({
    monitors: {},
    loadingMonitors: false,
  });
  const maintenanceStore = MaintenanceStore.create({
    maintenances: {},
    loadingMaintenances: false,
  });
  const noteStore = NoteStore.create({
    notes: [],
    loadigNotes: false,
  });

  const store = RootStore.create({
    metricStore,
    maintenanceStore,
    noteStore,
  });

  return store;
}
