import { createTheme } from '@mui/material/styles';

function useTheme() {
  const textColorPrimary = '#494D4B';

  const theme = createTheme({
    palette: {
      text: {
        primary: textColorPrimary,
      },
    },
    typography: {
      allVariants: {
        color: textColorPrimary,
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: 0,
        marginInlineEnd: 0,
      },
    },
  });

  return theme;
}

export default useTheme;
