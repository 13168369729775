import { types } from 'mobx-state-tree';

const NoteModel = types
  .model({
    id: types.string,
    type: types.enumeration('Type', ['error', 'warning', 'info', 'success']),
    title: types.string,
    description: types.string,
  });

export default NoteModel;
