import { types } from 'mobx-state-tree';

const MaintenanceModel = types
  .model({
    id: types.string,
    name: types.string,
    description: types.string,
    enabled: types.boolean,
    type: types.string,
    scheduleRecurenceType: types.string,
    scheduleStart: types.maybeNull(types.Date),
    scheduleEnd: types.maybeNull(types.Date),
    nextWindowStartsAt: types.Date,
    nextWindowEndsAt: types.Date,
    isPublic: types.boolean,
  }).views((self) => ({
    get durationMinutes() {
      return Math.round((self.nextWindowEndsAt.getTime() - self.nextWindowStartsAt.getTime()) / 60_000);
    },
  }));

export default MaintenanceModel;
