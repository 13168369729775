import { flow, types } from 'mobx-state-tree';

import { getDashboardMonitors, getAvailableMonitors, getEvents } from '../api/dynatrace';
import MonitorModel from './models/MonitorModel';

const MetricStore = types.model('MetricStore', {
  dashboardMonitors: types.array(types.string),
  events: types.array(types.string),
  monitors: types.map(MonitorModel),
  loadingMonitors: types.optional(types.boolean, false),
})
  .actions((self) => ({
    addDashboardMonitor(dashboardMonitor) {
      self.dashboardMonitors.push(dashboardMonitor);
    },
    addEvent(eventEntityId) {
      self.events.push(eventEntityId);
    },
    addMonitor(monitor) {
      self.monitors.set(monitor.id, monitor);
    },
    fetchDashboardMonitors: flow(function* fetchDashboardMonitors() { // <- note the star, this a generator function!
      // ... yield can be used in async/await style
      yield getDashboardMonitors()
        .then((response) => {
          response.data.tiles.forEach((tile) => {
            if (tile.name === 'HTTP monitor') {
              self.addDashboardMonitor(tile.assignedEntities[0]);
            }
          });
        });
    }),
    fetchEvents: flow(function* fetchEvents() { // <- note the star, this a generator function!
      // ... yield can be used in async/await style
      yield getEvents()
        .then((response) => {
          response.data.events.forEach((event) => {
            if (event?.entityId?.entityId.type === 'HTTP_CHECK') {
              self.addEvent(event.entityId.entityId.id);
            }
          });
        });
    }),
    fetchMonitors: flow(function* fetchMonitors() { // <- note the star, this a generator function!
      self.loadingMonitors = true;
      // ... yield can be used in async/await style
      yield self.fetchDashboardMonitors();
      yield self.fetchEvents();
      yield getAvailableMonitors()
        .then((response) => {
          response.data.monitors.sort((a, b) => (a.name > b.name ? 1 : -1));
          response.data.monitors.forEach((monitor) => {
            if (self.dashboardMonitors.includes(monitor.entityId)) {
              self.addMonitor({
                id: monitor.entityId,
                name: monitor.name,
                type: monitor.type,
                enabled: monitor.enabled,
                metrics: null,
                up: !self.events.includes(monitor.entityId),
                loadingMetrics: null,
              });
            }
          });
        });
      self.loadingMonitors = false;
    }),
  }));

export default MetricStore;
